import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Container, Link } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import { Link as RouterLink } from "react-router-dom";

import IconLink from "./IconLink";
import Footer from "./Footer";

function HomePage() {
  //   console.log("Hi there! My name is May Thura Lwin! Nice to meet you!");
  const LINKEDIN_URL = "https://www.linkedin.com/in/maythuralwin/";
  const GITHUB_URL = "https://github.com/MayThura";
  const EMAIL_MAIL_TO = "mailto:maythuralwin487@gmail.com";
  const RESUME_URL = "https://www.maythuralwin.com/assets/resume.pdf";
  const rainbowColors = [
    "red",
    "orange",
    "#FFDB58", // mustard yellow
    "green",
    "blue",
    "indigo",
    "violet",
  ];
  const [color, setColor] = useState("green");
  const [prevColor, setPrevColor] = useState("green");
  const [currentTime, setCurrentTime] = useState(new Date());
  const [backgroundColor, setBackgroundColor] = useState("#e6ecf5");

  const colorOptions = [
    "#FFC300", // Yellow
    "#DAF7A6", // Light Green
    "#33FFBD", // Green
    "#5cf7ff", // Light Blue
    "#e6ecf5", // Almost White
  ];

  const handleHover = () => {
    const randomColor =
      rainbowColors[Math.floor(Math.random() * rainbowColors.length)];
    if (randomColor === prevColor) {
      setColor("black");
      setPrevColor("black");
    } else {
      setColor(randomColor);
      setPrevColor(randomColor);
    }
  };

  useEffect(() => {
    // Update the clock every second
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  const handleColorChange = (color) => {
    setBackgroundColor(color);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        height: "100vh",
        backgroundColor: backgroundColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        margin: 0,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="100vh"
        sx={{ backgroundColor: backgroundColor }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Box sx={{ mt: 10, mb: 5 }} display="flex" justifyContent="center">
              <img
                src="may_profile_small.jpg"
                alt="May Thura Lwin"
                style={{ maxWidth: "100%", height: "40vh" }}
                width="auto"
                height="auto"
                loading="lazy"
              />
            </Box>
            <Typography variant="h3" component="h1" gutterBottom align="center">
              May Thura Lwin
            </Typography>
            <Typography
              color={color}
              variant="h5"
              align="center"
              sx={{
                cursor: "pointer",
              }}
              onMouseEnter={handleHover} // For Desktop User
              onTouchStart={handleHover} // For Mobile User
            >
              <span role="img" aria-label="Woman Software Engineer">
                👩‍💻
              </span>{" "}
              Software Engineer
            </Typography>
            <Box sx={{ m: 4 }} display="flex" justifyContent="center">
              <Grid container spacing={2} justifyContent="center">
                <IconLink href={LINKEDIN_URL} IconComponent={LinkedInIcon} />
                <IconLink href={GITHUB_URL} IconComponent={GitHubIcon} />
                <IconLink href={EMAIL_MAIL_TO} IconComponent={EmailIcon} />
              </Grid>
            </Box>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Link href={LINKEDIN_URL} target="_blank">
                  <Typography
                    variant="h6"
                    component="h5"
                    gutterBottom
                    align="center"
                    sx={{
                      color: "black",
                      "&:hover": {
                        color: "darkblue",
                      },
                    }}
                  >
                    LinkedIn
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link href={RESUME_URL} target="_blank">
                  <Typography
                    variant="h6"
                    component="h5"
                    gutterBottom
                    align="center"
                    sx={{
                      color: "black",
                      "&:hover": {
                        color: "darkblue",
                      },
                    }}
                  >
                    Resumé
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link href={GITHUB_URL} target="_blank">
                  <Typography
                    variant="h6"
                    component="h5"
                    gutterBottom
                    align="center"
                    sx={{
                      color: "black",
                      "&:hover": {
                        color: "darkblue",
                      },
                    }}
                  >
                    Github
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Color Picker */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1" color="black" gutterBottom>
            {/* Choose Background Color: */}
          </Typography>
          <Box
            display="flex"
            sx={{
              backgroundColor: "white",
            }}
          >
            {colorOptions.map((color, index) => (
              <Box
                key={index}
                sx={{
                  width: 30,
                  height: 30,
                  backgroundColor: color,
                  cursor: "pointer",
                  borderRadius: "4px",
                  margin: "4px",
                }}
                onClick={() => handleColorChange(color)}
              />
            ))}
          </Box>
        </Box>

        {/* Link to Quote Picker Page */}
        <Box sx={{ marginTop: 2 }}>
          <Typography
            variant="body1"
            component={RouterLink}
            to="/quote"
            sx={{
              color: "black",
              //   textDecoration: "none",
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Quote of the Day
          </Typography>
        </Box>

        {/* Clock Display at the bottom center */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "max(10%, 120px)",
            padding: 2,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            borderRadius: "8px",
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <Typography variant="body1">
            {currentTime.toLocaleTimeString()}
          </Typography>
        </Box>

        <Footer color={backgroundColor} />
      </Box>
    </Container>
  );
}

export default HomePage;
