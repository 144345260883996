export const PROJECTS = [
  {
    name: "Online Apparel Shopping Website",
    description:
      "This web application is designed to offer an engaging and intuitive shopping experience for users looking to purchase apparel online. Built with React JS, this project incorporates modern web development practices to ensure a seamless and efficient user interaction.",
    images: [
      "shop/shop_1.jpeg",
      "shop/shop_2.jpeg",
      "shop/shop_3.jpeg",
      "shop/shop_4.jpeg",
      "shop/shop_5.jpeg",
      "shop/shop_6.jpeg",
    ],
  },
  {
    name: "Risk Data Visualizer",
    description:
      "This project is designed to visualize risk data across different years, showcasing what kinds of disasters and risks have impacted various assets. It presents data in three distinct styles: on a map, in a detailed table, and through data graphs, allowing users to interactively explore and understand the risk factors and ratings associated with different assets.",
    images: [
      "risk_visualizers/1.png",
      "risk_visualizers/2.png",
      "risk_visualizers/3.png",
      "risk_visualizers/4.png",
    ],
  },
  {
    name: "Happy Residents Manager",
    description:
      "This MVP application is designed to streamline the management of retirement homes, making it easier for managers to handle resident information and program scheduling. Users can effortlessly add new residents and programs through dedicated tabs, with changes instantly updated in the database and reflected on the frontend.",
    images: ["happy_resident_manager.png"],
  },
];

/*
Online Apparel Shopping Project - SS
(Disaster) Risk Vizualization Project
Happy Residents Manager - SS

STL Manager - SS
File Explorer - SS
University Garage - Abstract
Face Detection App - Abstract (MVP)
Camera App - Abstract (MVP 2017)
Swag Shop - MERN / MongoDB - SS
Html CSS Project (Crazy Kitchen for Kids Web Page) - Pending
SASS Page - SS

  
  */
