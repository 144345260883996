import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

import MediaCover from "./MediaCover";

function ProjectItem({ projectName, projectDescription, imageSources }) {
  const alignment = "center";
  return (
    <Grid item spacing={2} sx={{ mb: 10 }} justifyContent="center">
      <Typography
        sx={{ mt: 2 }}
        variant="h5"
        component="h2"
        gutterBottom
        align={alignment}
      >
        {projectName}
      </Typography>
      <Typography
        sx={{ mt: 1 }}
        variant="paragraph"
        component="p"
        gutterBottom
        color={blueGrey[300]}
        align={alignment}
      >
        {projectDescription}
      </Typography>
      <Box sx={{ mt: 2, mb: 5 }} display="flex" justifyContent={alignment}>
        <MediaCover imageSources={imageSources} projectName={projectName} />
        {/* <img
          src={imageSources}
          alt={projectName}
          style={{ maxWidth: "100%", height: "30vh" }}
        /> */}
        {/* <ImageCarousel imageSources={imageSources} projectName={projectName} /> */}
      </Box>
    </Grid>
  );
}

export default ProjectItem;
