import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import HomeIcon from "@mui/icons-material/Home"; // Import Home icon
import Confetti from "react-confetti";

const quotes = [
  "Never give up on something that you can't go a day without thinking about.",
  "Your limitation—it’s only your imagination.",
  "Push yourself, because no one else is going to do it for you.",
  "Sometimes later becomes never. Do it now.",
  "Great things never come from comfort zones.",
  "Dream it. Wish it. Do it.",
  "Success doesn’t just find you. You have to go out and get it.",
  "The harder you work for something, the greater you’ll feel when you achieve it.",
  "Dream bigger. Do bigger.",
  "Don’t stop when you’re tired. Stop when you’re done.",
  "Wake up with determination. Go to bed with satisfaction.",
  "Do something today that your future self will thank you for.",
  "Little things make big days.",
  "It’s going to be hard, but hard does not mean impossible.",
  "Don’t wait for opportunity. Create it.",
];

function QuotePickerPage() {
  const [quote, setQuote] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);
  const [showTitle, setShowTitle] = useState(true);
  const [showButton, setShowButton] = useState(true); // State to control button visibility

  const handleQuoteButtonClick = () => {
    // Pick a random quote
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    setQuote(randomQuote);
    setShowConfetti(true);
    setShowButton(false); // Hide button after click
    setShowTitle(false); // Hide title after click

    setTimeout(() => setShowConfetti(false), 1000 * 60 * 10); // 10 minutes confetti
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      sx={{
        fontFamily: "'Montserrat', sans-serif", // New font for the entire page
        background:
          "linear-gradient(45deg, #FF5733, #FF8D1A, #FFC300, #33FFBD, #3380FF, #8C33FF)",
        backgroundSize: "400% 400%",
        animation: "gradientAnimation 15s ease infinite", // Animation for background gradient
        "@keyframes gradientAnimation": {
          "0%": { backgroundPosition: "0% 50%" },
          "50%": { backgroundPosition: "100% 50%" },
          "100%": { backgroundPosition: "0% 50%" },
        },
      }}
    >
      {/* Home Icon Link */}
      <Box
        sx={{
          position: "absolute",
          top: 16,
          left: 16,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <Link to="/" style={{ color: "white", textDecoration: "none" }}>
          <HomeIcon fontSize="large" sx={{ color: "black" }} />
        </Link>
      </Box>
      {showTitle && (
        <Typography variant="h4" component="h1" gutterBottom>
          Get Your Quote of the Day!
        </Typography>
      )}

      {/* Conditionally render the button */}
      {showButton && (
        <Button
          onClick={handleQuoteButtonClick}
          sx={{
            marginTop: 2,
            fontSize: "20px",
            padding: "10px 20px",
            borderRadius: "20px",
            backgroundColor: "black", // Custom background color
            color: "white", // Set text color to black for contrast
            "&:hover": {
              backgroundColor: "#4fc6e5", // Darker shade on hover
            },
            animation: "bounce 2s infinite",
            "@keyframes bounce": {
              "0%, 100%": {
                transform: "translateY(0)",
              },
              "50%": {
                transform: "translateY(-15px)",
              },
            },
          }}
        >
          Get Quote
        </Button>
      )}

      {/* Display the quote */}
      {quote && (
        <Box
          sx={{
            mt: 4,
            p: 3,
            background:
              "linear-gradient(135deg, #2c3e50, #34495e, #2c3e50, #34495e)", // Gradient background
            backgroundSize: "200% 200%",
            color: "white", // White text color for the quote
            borderRadius: "10px", // Rounded corners for a softer look
            maxWidth: "80%", // Max width to keep it centered
            textAlign: "center", // Center align text
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.5)", // Shadow for depth
            animation: "quoteGradient 10s ease infinite", // Animation for gradient background
            "@keyframes quoteGradient": {
              "0%": { backgroundPosition: "0% 50%" },
              "50%": { backgroundPosition: "100% 50%" },
              "100%": { backgroundPosition: "0% 50%" },
            },
          }}
        >
          <Typography variant="h3" component="p">
            {quote}
          </Typography>
        </Box>
      )}

      {/* Show confetti animation */}
      {showConfetti && <Confetti />}
    </Box>
  );
}

export default QuotePickerPage;
