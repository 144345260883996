import * as React from "react";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";

export default function MediaCover({ imageSources, projectName }) {
  if (imageSources.length > 1) {
    return (
      <Box
        component="ul"
        sx={{ display: "flex", gap: 2, flexWrap: "wrap", p: 0, m: 0 }}
      >
        {imageSources.map((imageSource, idx) => (
          <Card component="li" sx={{ minWidth: 300, flexGrow: 1 }}>
            <CardCover>
              <img
                src={imageSource}
                srcSet={imageSource}
                loading="lazy"
                alt={projectName}
              />
            </CardCover>
            <CardContent>
              <Typography
                level="body-lg"
                fontWeight="lg"
                textColor="#fff"
                mt={{ xs: 12, sm: 18 }}
              >
                Image
              </Typography>
            </CardContent>
          </Card>
        ))}
        {/* 
      <Card component="li" sx={{ minWidth: 300, flexGrow: 1 }}>
        <CardCover>
          <video
            autoPlay
            loop
            muted
            poster="https://assets.codepen.io/6093409/river.jpg"
          >
            <source
              src="https://assets.codepen.io/6093409/river.mp4"
              type="video/mp4"
            />
          </video>
        </CardCover>
        <CardContent>
          <Typography
            level="body-lg"
            fontWeight="lg"
            textColor="#fff"
            mt={{ xs: 12, sm: 18 }}
          >
            Video
          </Typography>
        </CardContent>
      </Card> */}
      </Box>
    );
  } else {
    // Return this JSX when imageSources length is not greater than 1
    return (
      <img
        src={imageSources}
        alt={projectName}
        style={{ maxWidth: "100%", height: "30vh" }}
      />
    );
  }
}
