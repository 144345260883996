import React from "react";
import {
  Box,
  Grid,
  Container,
  Divider,
  Typography,
  Link,
  Button,
  AppBar,
  Toolbar,
} from "@mui/material";

import Footer from "./Footer";
import ProjectItem from "./ProjectItem";
import { PROJECTS } from "./Constants";

function ProjectPage() {
  return (
    <Container>
      <Link href="/">
        <Typography
          variant="h6"
          component="h3"
          gutterBottom
          align="right"
          sx={{
            color: "black",
            "&:hover": {
              color: "darkblue",
            },
            mt: 3,
            mb: 6,
          }}
        >
          Home
        </Typography>
      </Link>
      <Box
        display="flex"
        height="100vh"
        alignItems="top"
        justifyContent="center"
      >
        <Grid container justifyContent="center">
          <Grid item xs={9}>
            <Typography
              sx={{ mt: 3 }}
              variant="h4"
              component="h1"
              gutterBottom
              align="center"
            >
              Projects
            </Typography>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ m: 3 }}
            >
              <Divider sx={{ width: "75%" }} />
            </Grid>
            {PROJECTS.map((project, _) => (
              <ProjectItem
                projectName={project.name}
                projectDescription={project.description}
                imageSources={project.images}
              />
            ))}
            <Typography
              sx={{ mt: 3 }}
              variant="h6"
              component="h3"
              gutterBottom
              align="center"
            >
              More on{" "}
              <Link
                href="https://www.linkedin.com/in/maythuralwin/"
                target="_blank"
              >
                LinkedIn
              </Link>
            </Typography>
            <Footer />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default ProjectPage;
