import React, { useState } from "react";
import { Grid, Link, SvgIcon, Tooltip } from "@mui/material";

const IconLink = ({ href, IconComponent }) => {
  const [iconColor, setIconColor] = useState("action"); // Default color

  return (
    <Grid item>
      <Tooltip title={href} placement="bottom" arrow>
        <Link
          href={href}
          target="_blank"
          underline="none"
          onMouseEnter={() => setIconColor("primary")}
          onMouseLeave={() => setIconColor("action")}
          sx={{
            "&:hover": {
              // Customizing Tooltip background and text color
              "& .MuiTooltip-tooltip": {
                backgroundColor: "#000", // Black background
                color: "#fff", // White text
              },
            },
          }}
        >
          <SvgIcon component={IconComponent} color={iconColor} />
        </Link>
      </Tooltip>
    </Grid>
  );
};

export default IconLink;
